import { Box, Text } from "grommet";
import getFormattedCap from "@organisms/coin/getFormattedCap";
import { useCoin } from "@organisms/coin/provider";
import DividerCircle from "organisms/coin/list/dividerCircle";

const CapNumber = ({ slug, label, value }) => {
  const { presaleCurrency } = useCoin(slug);

  return (
    <Box>
      <Text size="xsmall" color="dark-4" alignSelf="start">
        {label}{" "}
      </Text>
      {value && (
        <Text size="small">{getFormattedCap(value, presaleCurrency)}</Text>
      )}
      {!value && (
        <Text size="small" color="dark-4">
          -
        </Text>
      )}
    </Box>
  );
};

const Cap = ({ slug, ...rest }) => {
  const { presaleSoftcap, presaleHardcap } = useCoin(slug);

  return (
    <Box direction="row" gap="xsmall" align="center" justify="evenly" {...rest}>
      <CapNumber slug={slug} label="Softcap" value={presaleSoftcap} />
      {presaleSoftcap && <DividerCircle />}
      <CapNumber slug={slug} label="Hardcap" value={presaleHardcap} />
    </Box>
  );
};

export default Cap;
